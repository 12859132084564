.container {
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
  }
  
  .title {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }
  
  .post-content {
    padding: 20px;
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  