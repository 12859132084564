.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.content{
  min-height: 100vh - 15px;
}

.rightBar{
  width: '250px';
  flex: '0 0 auto';
  padding: '20px';
  background: 'white';
}
#social_icon:hover{
  color:#007bff !important;


}
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.list-item:hover #list-item-title{
  color: rgba(255, 82, 82, 0.8) !important;
}
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdd4ec; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007BFF; 
}
/* header.css */

.header-container {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 100;
}



.subButton {
  margin: 0 auto;
}

/* .blog-link {
  text-decoration: none;
  color: #173cbe;
  transition: color 0.3s ease;
}

.blog-link:hover {
  color: #0c278a;
} */
#onHover:hover{
  background-color: rgba(255, 82, 82, 0.8) !important;
}
.css-rowgae {
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  display: block;
  background-color: white;
  display:flex !important;
}
/* Styling for the loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999; /* Adjust the z-index as needed */
}
