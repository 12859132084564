.wrapp {
    width: 80%;
    display: flex;
    gap: 5rem;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
  }
  .info {
    display: flex;
    padding: 1rem;
    background-color: black;
    color: white;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  .icon {
    color: var(--white);
    background: rgb(25, 22, 43);
    padding: 1rem;
    display: flex;
    font-size: 2rem;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
  }
  .info.p {
    max-width: 500px;
    margin: 0px auto;
    font-size: 1.8rem;
    line-height: 1.3em;
  }
  .left {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .line {
    width: 2px;
    height: 30rem;
    background-color: brown;
  }
  
  .right {
    width: 50%;
  }
  
  .right input,
  .right textarea {
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
    color: white;
    background-color: black;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-top: 0.5rem;
    resize: vertical;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .form-group label {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .wrapp button {
    background-color: #bcb4b4;
    color: black;
    font-size: 1rem;
    display: block;
    outline: none;
    border: 2px solid none;
    padding: 0.5rem 2rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 1s ease;
  }
  
  .wrapp button:hover {
    transform: scale(0.9);
  }
  .map {
    width: 100%;
    margin: 3rem 0;
  }
  
  .map iframe {
    width: 80%;
    height: 300px;
    opacity: 0.7;
  }
  
  .email {
    word-break: break-all;
  }
  @media screen and (max-width:1225px){
      .wrapp{
          flex-direction: column;
          align-items:center;
      }
      .left, .right{
          width: 90%;
      }
      .line{
          width: 25rem;
          height: 2px;
      }
      .info{
          gap: 1rem;
      }
  
      .map{
          margin: 6rem 0;
      }
      .map iframe{
          width: 75%
      }
      
    };

  @media screen and (max-width:1225px){
      .wrapp{
          flex-direction: column;
          align-items:center;
      }
      .left, .right{
          width: 90%;
      }
      .line{
          width: 25rem;
          height: 2px;
      }
      .info{
          gap: 1rem;
      }
  
      .map{
          margin: 6rem 0;
      }
      .map iframe{
          width: 75%
      }
      
    };
  
  

    
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans:ital@1&display=swap');
  
  body{  background: rgb(255, 255, 255);
  width:100vw;
  height:100%;
  max-width:100%;
  text-align:center;
  /* align-items: center; */
    display: flex;
    flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  }
  
  h2{
    font-size:4rem;
  }
  p{
    letter-spacing: .1rem;
  }
    label {
      color: white
    }
  
  