/* SubCategories.css */
.list-item {
    width: 100%;
  }
  
  .list-item-title {
    opacity: 1;
    font-size: 1rem;
    color: black;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
    cursor: pointer;
  }
  
  .edit-button {
    background: transparent;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button:hover {
    background-color: rgba(255, 82, 82, 0.8);
  }
  
  .delete-button {
    background: transparent;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .delete-button:hover {
    background-color: rgba(255, 82, 82, 0.8);
  }
    

  /* SubcategoryPage.css */

/* Styles for the main container */
.subcategory-page {
    margin-left: 20px;
  }
  
  /* Styles for the heading */
  .subcategory-heading {
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
  
  /* Styles for the subcategory name */
  .subcategory-name {
    font-weight: normal;
  }
  
  /* Styles for the list container */
  .list-container {
    list-style: none;
    padding: 0;
  }
  
  /* Styles for the post image */
  .post-image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-bottom: 16px;
  }
  
  /* Styles for the post title */
  .post-title {
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 16px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  /* Styles for admin buttons */
  .admin-buttons {
    display: flex;
    justify-content: space-around;
  }
  